import React, { useState } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";

const DeleteAccountModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    setConfirmationText("");
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setConfirmationText("");
  };

  const handleDelete = async () => {
    if (confirmationText !== "DELETE") {
      alert("Please type DELETE to confirm.");
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.delete(
        process.env.REACT_APP_API_DELETE_ACCOUNT
      ); // Update with your backend endpoint
      alert("Account deleted successfully!");
      closeModal();
    } catch (error) {
      console.error("Error deleting account:", error);
      alert("Failed to delete account. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {/* Button to open the modal */}
      <Button
        onClick={openModal}
        className="open-modal-button"
        style={{ backgroundColor: "red" }}
      >
        {" "}
        Delete Account
      </Button>

      {/* Modal content */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Are you sure you want to delete your account?</h2>
            <p>
              This action cannot be undone. To confirm, type{" "}
              <strong>DELETE </strong>
              below.
            </p>
            <input
              type="text"
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
              placeholder="Type DELETE"
              disabled={isLoading}
              className="confirmation-input"
            />

            <div className="modal-buttons">
              <Button
                onClick={handleDelete}
                disabled={confirmationText !== "DELETE" || isLoading}
                className="confirm-button"
              >
                {isLoading ? "Deleting..." : "Confirm"}
              </Button>
              <Button onClick={closeModal} className="cancel-button">
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteAccountModal;
