import { Form, Button } from "react-bootstrap";
import axios from "axios";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import VerificationInput from "react-verification-input";

export default function ForgotPassword(props) {
  const [username, setUsername] = useState({ username: "" });
  const [password, setPassword] = useState({ password: "" });
  const [code, setCode] = useState("");
  const [message, setMessage] = useState(null);
  const [verfied, setVerified] = useState(false);

  function sendEmail() {
    axios
      .post(
        process.env.REACT_APP_API_VERIFYPASSWORD,
        { username: username },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        setMessage(res.data.message);
        setCode(res.data.code);
      })
      .catch((err) => {
        err.data.message ? setMessage(err.data.message) : console.log(err.data);
      });
  }

  function verification(e) {
    if (e == code) {
      setMessage("SUCCESS");
      setVerified(true);
    }
  }

  function updatePassword() {
    axios
      .post(
        process.env.REACT_APP_API_UPDATEPASSWORD,
        { username: username, password: password },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        props.setShowPassword(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Modal show={props.showPassword} onHide={props.close}>
      <Modal.Header closeButton>
        <Modal.Title>Update Password</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          {/* email */}
          <Form.Group controlId="passwordformBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              disabled={verfied}
              type="email"
              placeholder="Enter email"
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>
          {code.length > 0 ? (
            <VerificationInput length={4} onComplete={(e) => verification(e)} />
          ) : null}
          {verfied ? (
            <>
              <Form.Group controlId="passwordformBasicPassword">
                <Form.Label>Enter new password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Button
                style={{ marginRight: 40, margin: 10 }}
                onClick={(e) => {
                  e.preventDefault();
                  updatePassword();
                }}
                variant="primary"
                type="submit"
              >
                Submit{" "}
              </Button>
            </>
          ) : null}

          {code.length == 0 ? (
            <Button
              style={{ marginRight: 40, margin: 10 }}
              onClick={(e) => {
                e.preventDefault();
                sendEmail();
              }}
              variant="primary"
              type="submit"
            >
              Submit{" "}
            </Button>
          ) : null}
        </Form>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}
