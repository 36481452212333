import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Icon from "react-crud-icons";
import React from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";

function StockModal(props) {
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Stock</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Stock Ticker</Form.Label>
              <Form.Control type="text" value={props.stockDataTicker.ticker} />
            </Form.Group>
            <>
              <h4>Purchased</h4>

              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Cost</Form.Label>
                    {props.buyData.map((x, i) => (
                      <Form.Control
                        type="number"
                        className="mb-3"
                        placeholder={props.buyData[i].cost}
                        autoFocus
                        onChange={(e) =>
                          props.updateStage(i, props.setBuyData, {
                            noOfSharesBought: props.buyData[i].noOfSharesBought,
                            cost: parseInt(e.target.value),
                            date: props.buyData[i].date,
                          })
                        }
                      />
                    ))}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>No of Shares</Form.Label>
                    {props.buyData.map((x, i) => (
                      <Form.Control
                        className="mb-3"
                        type="number"
                        placeholder={props.buyData[i].noOfSharesBought}
                        autoFocus
                        onChange={(e) =>
                          props.updateStage(i, props.setBuyData, {
                            noOfSharesBought: parseInt(e.target.value),
                            cost: props.buyData[i].cost,
                            date: props.buyData[i].date,
                          })
                        }
                      />
                    ))}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Date Added</Form.Label>
                    {props.buyData.map((x, i) => (
                      <Form.Control
                        className="mb-3"
                        type="date"
                        autoFocus
                        value={props.buyData[i].date}
                        onChange={(e) =>
                          props.updateStage(i, props.setBuyData, {
                            noOfSharesBought: props.buyData[i].noOfSharesBought,
                            cost: props.buyData[i].cost,
                            date: e.target.value,
                          })
                        }
                      />
                    ))}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col></Col> <Col></Col>
                <Col>
                  <Icon
                    name="add"
                    tooltip="Add"
                    theme="light"
                    size="small"
                    onClick={() => props.addBuyRow()}
                  />
                  <Icon
                    name="remove"
                    tooltip="Remove"
                    theme="light"
                    size="small"
                    onClick={() => {
                      props.removeBuyRow();
                    }}
                  />
                </Col>
              </Row>
            </>
            <h4>Sold</h4>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Sold"
                onChange={() => props.setSold(!props.sold)}
                checked={props.sold}
              />
              {props.sold ? (
                <>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Value</Form.Label>
                        {props.soldData.map((x, i) => (
                          <Form.Control
                            type="number"
                            className="mb-3"
                            placeholder={props.soldData[i].value}
                            autoFocus
                            onChange={(e) =>
                              props.updateStage(i, props.setSoldData, {
                                value: parseInt(e.target.value),
                                date: props.soldData[i].date,
                                noOfSharesSold:
                                  props.soldData[i].noOfSharesSold,
                              })
                            }
                          />
                        ))}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>No of Shares</Form.Label>
                        {props.soldData.map((x, i) => (
                          <Form.Control
                            type="number"
                            className="mb-3"
                            placeholder={props.soldData[i].noOfSharesSold}
                            autoFocus
                            onChange={(e) =>
                              props.updateStage(i, props.setSoldData, {
                                noOfSharesSold: parseInt(e.target.value),
                                value: props.soldData[i].value,
                                date: props.soldData[i].date,
                              })
                            }
                          />
                        ))}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput4"
                      >
                        <Form.Label>Date Sold</Form.Label>
                        {props.soldData.map((x, i) => (
                          <Form.Control
                            className="mb-3"
                            type="date"
                            autoFocus
                            onChange={(e) =>
                              props.updateStage(i, props.setSoldData, {
                                date: e.target.value,
                                value: props.soldData[i].value,
                                noOfSharesSold:
                                  props.soldData[i].noOfSharesSold,
                              })
                            }
                            value={props.soldData[i].date}
                          />
                        ))}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col></Col> <Col></Col>
                    <Col>
                      <Icon
                        name="add"
                        tooltip="Add"
                        theme="light"
                        size="small"
                        onClick={() => props.addSellRow()}
                      />
                      <Icon
                        name="remove"
                        tooltip="Remove"
                        theme="light"
                        size="small"
                        onClick={() => {
                          props.removeSellRow();
                        }}
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <></>
              )}
            </Form.Group>
          </Form>
          <h4>Dividends</h4>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Amount</Form.Label>
                {props.rows.map((x, index) => (
                  <Form.Control
                    type="number"
                    className="mb-3"
                    placeholder={props.divAmount[index]}
                    autoFocus
                    onChange={(e) => {
                      props.setDivAmount((prevState) => ({
                        ...prevState,
                        [index]: parseInt(e.target.value),
                      }));
                    }}
                  />
                ))}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Date</Form.Label>
                {props.rows.map((x, index) => {
                  const dateValue = props.startDate[index];
                  const formattedDate = dateValue
                    ? new Date(dateValue).toISOString().slice(0, 10)
                    : ""; // Check for a valid date

                  return (
                    <Form.Control
                      key={index}
                      value={formattedDate}
                      className="mb-3"
                      type="date"
                      onChange={(e) => {
                        props.setStartDate((prevState) => ({
                          ...prevState,
                          [index]: e.target.value, // use e.target.value for "yyyy-MM-dd"
                        }));
                      }}
                    />
                  );
                })}
              </Form.Group>
            </Col>
          </Row>

          <Icon
            name="add"
            tooltip="Add"
            theme="light"
            size="medium"
            onClick={() => props.addRow()}
          />
          <Icon
            name="remove"
            tooltip="Remove"
            theme="light"
            size="medium"
            onClick={() => {
              props.removeRow();
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          {props.text === "Update" ? (
            <Button
              variant="secondary"
              onClick={() => {
                props.setShowInfo(true);
                props.handleClose();
              }}
            >
              Back
            </Button>
          ) : null}
          <Button
            variant="primary"
            onClick={
              props.text === "Add" ? props.handleAndAdd : props.handleAndEdit
            }
          >
            {props.text}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default StockModal;
